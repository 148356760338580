<template>
	<w-flex scroll-y>
		<w-layout column fill-height>
			<v-toolbar color="primary" dark flat height="78px">
				<w-layout align-center row>
					<template v-if="isEditing || step !== 1">
						<w-flex grow>
							<w-layout column>
								<v-toolbar-title v-text="project.name" />
								<w-flex v-if="project.start && project.end" caption>
									{{
										$t('projects.date_from_to', {
											from: $d(new Date(project.start)),
											to: $d(new Date(project.end))
										})
									}}
								</w-flex>
							</w-layout>
						</w-flex>
						<w-flex v-if="project.id && project.status" shrink>
							<v-chip :color="project.status.color" small>{{ project.status.name }}</v-chip>
						</w-flex>
					</template>
					<template v-else>
						<w-flex grow>
							<w-layout row>
								<w-flex grow mr-1>
									<w-text-input v-model="project.name" autofocus color="white" :label="$t('projects.project_name')" maxlength="191" required />
								</w-flex>
								<w-flex ml-1 shrink>
									<w-date-range-picker
										:clearable="false"
										input-color="white"
										:first-date.sync="project.start"
										open-on-focus
										required
										:second-date.sync="project.end"
										small
									/>
								</w-flex>
							</w-layout>
						</w-flex>
					</template>
					<w-flex shrink>
						<w-btn color flat icon="close" mini @click="closeWizard()">{{ $t('actions.close') }}</w-btn>
					</w-flex>
				</w-layout>
			</v-toolbar>
			<w-flex scroll-y>
				<CatalogServiceSelector v-show="step === 1" v-model="catalogServices" />
				<ServicesForm v-show="step === 2" v-model="servicesDatas" :project="project" />
			</w-flex>
			<w-flex py-2 shrink text-xs-center>
				<w-btn v-if="step === 1" :disabled="!project.is_valid || catalogServices.length === 0" @click="next()">{{ $t('next') }}</w-btn>
				<template v-else>
					<w-btn flat @click="previous()">{{ $t('previous') }}</w-btn>
					<w-btn @click="save()">{{ $t('actions.save') }}</w-btn>
				</template>
			</w-flex>
		</w-layout>
	</w-flex>
</template>

<script>
import { mapState } from 'vuex'

import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'
import Project from '@/classes/Offers/Project'

export default {
	name: 'ProjectWizard',
	components: {
		CatalogServiceSelector: () => ({
			component: import('@/components/Offers/CustomersManager/Forms/CatalogServicesSelector')
		}),
		ServicesForm: () => ({
			component: import('@/components/Offers/CustomersManager/Forms/ServicesForm')
		})
	},
	mixins: [ProjectsManagerModuleGuard],
	props: {
		projectId: {
			required: false,
			type: Number,
			default: undefined
		}
	},
	data: function () {
		return {
			catalogServices: [],
			loadings: false,
			project: new Project(),
			servicesDatas: [],
			step: 1
		}
	},
	computed: {
		...mapState({
			statuses: state => state.customers.projects.statuses
		}),
		isEditing: function () {
			return !!this.projectId
		}
	},
	watch: {
		projectId: {
			handler: function (newVal, oldVal) {
				if (newVal === oldVal) {
					return
				}

				if (newVal) {
					this.findProject()
				} else {
					this.resetProject()
				}
			},
			immediate: true
		}
	},
	created: function () {
		if (!this.projectId) {
			this.setDefaultProject()
		}
	},
	methods: {
		closeWizard: function () {
			this.appService.goTo('customer-services')
		},
		decrementLoadings: function () {
			this.loadings--

			if (this.loadings < 1) {
				this.$emit('loaded')
			}
		},
		findProject: function () {
			this.incrementLoadings()
			return this.service
				.findProject(this.projectId)
				.then(project => {
					this.project = project
				})
				.finally(() => {
					this.decrementLoadings()
				})
		},
		incrementLoadings: function () {
			this.loadings++

			if (this.loadings > 0) {
				return this.$emit('loading')
			}
		},
		next: function () {
			this.servicesDatas = this.catalogServices.map(catalog_service => ({
				catalog_service,
				catalog_service_id: catalog_service.id,
				first_due_date: this.project.start,
				quantity: 1
			}))
			this.step++
		},
		previous: function () {
			this.step--
		},
		resetProject: function () {
			this.project = new Project()
		},
		save: async function () {
			if (!this.projectId) {
				this.project = await this.service.createProject(this.project)
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('projects.project_created'))
			}

			const project = this.project
			const promises = []

			this.servicesDatas.forEach(serviceData => {
				const service = serviceData.catalog_service.instanciate({
					project: project
				})

				const frequency = serviceData.frequency
				service.frequency = frequency
				service.frequency_ending_first = serviceData.first_due_date

				for (let i = 0; i < serviceData.quantity ?? 1; i++) {
					const promise = this.service.createService(service)
					promises.push(promise)
				}
			})

			return await Promise.allSettled(promises).then(() => {
				this.service.selectProject(project.id)
			})
		},
		setDefaultProject: function () {
			this.incrementLoadings()
			const notSignedStatus = this.statuses.find(status => status.key === 'not-signed')

			const callAnswer = this.service.listProjects(
				this.accountingFirmId,
				this.vendorId,
				{
					statuses: [notSignedStatus.id]
				},
				{
					page: 1,
					rowsPerPage: 1,
					sortBy: ['created_at'],
					sortDesc: [true]
				}
			)

			callAnswer.promise
				.then(({ projects }) => {
					if (projects.length === 0) {
						return
					}

					const project = projects[0]

					this.appService.goTo({
						name: 'customer-services-wizard',
						params: {
							projectId: project.id.toString(16)
						}
					})
				})
				.finally(() => {
					this.decrementLoadings()
				})
		}
	}
}
</script>
